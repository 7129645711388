import { Alpha3Countries, countries } from 'constants/countries';
import { useLocale } from 'hooks/locale';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

// Components
import {
  Address,
  Checkbox,
  DatePicker,
  DateSelector,
  Input,
  InputGroup,
  MultipleChoice,
  MultipleSelect,
  PhoneNumberPicker,
  Select,
  Textarea,
} from 'components/FormComponents';

import addValidators from '../../../helpers/validators/apply-validators';

export const DynamicTypeInput = ({
  fieldName,
  type = 'text',
  title,
  description,
  tooltip,
  autoComplete,
  placeholder,
  defaultValue,
  min,
  max,
  required = false,
  validatorName = null,
  validationParams = {},
  fieldOptions,
  useDefault = true,
  useSearch,
  locale = 'en_GB',
  register = () => {},
  watch = () => {},
  control,
  setValue,
  getValues,
  error,
  errorObject,
  noErrorMessage = false,
}) => {
  const { dateHyphenFormat } = useLocale(locale);

  const validators = addValidators(
    type,
    min,
    max,
    required,
    validatorName,
    validationParams,
    getValues,
    fieldName,
  );

  const render = () => {
    switch (type) {
      case 'text':
        return (
          <InputGroup title={title} description={description} tooltip={tooltip} error={error}>
            <Input
              name={fieldName}
              type={type}
              autoComplete={autoComplete}
              placeholder={placeholder || title}
              defaultValue={defaultValue}
              minLength={min}
              maxLength={max}
              validators={validators}
              register={register}
              error={error}
              noErrorMessage={noErrorMessage}
            />
          </InputGroup>
        );
      case 'textarea':
        return (
          <InputGroup title={title} description={description} tooltip={tooltip} error={error}>
            <Textarea
              name={fieldName}
              autoComplete={autoComplete}
              placeholder={placeholder || title}
              defaultValue={defaultValue}
              minLength={min}
              maxLength={max}
              validators={validators}
              dynamicHeight={false}
              register={register}
              error={error}
              noErrorMessage={noErrorMessage}
              inputProps={{ rows: 4 }}
            />
          </InputGroup>
        );
      case 'number':
        return (
          <InputGroup title={title} description={description} tooltip={tooltip} error={error}>
            <Input
              name={fieldName}
              type={type}
              autoComplete={autoComplete}
              placeholder={placeholder}
              defaultValue={
                defaultValue !== null && !isNaN(Number(defaultValue))
                  ? Number(defaultValue)
                  : undefined
              }
              min={min !== null ? Number(min) : undefined}
              max={max !== null ? Number(max) : undefined}
              validators={validators}
              register={register}
              error={error}
              noErrorMessage={noErrorMessage}
            />
          </InputGroup>
        );
      case 'address':
        return (
          <Address
            name={fieldName}
            value={watch(fieldName)}
            title={title}
            description={description}
            tooltip={tooltip}
            autoComplete={autoComplete}
            defaultValue={defaultValue}
            required={required}
            register={register}
            setValue={setValue}
            error={errorObject}
            noErrorMessage={noErrorMessage}
          />
        );
      case 'phone_number':
        return (
          <InputGroup title={title} description={description} tooltip={tooltip} error={error}>
            <Controller
              name={fieldName}
              defaultValue={defaultValue}
              control={control}
              rules={validators}
              render={({ field: { onChange, value }, fieldState: { error: fieldError } }) => (
                <PhoneNumberPicker
                  value={value}
                  autoComplete={autoComplete}
                  placeholder={placeholder || 'Enter phone number'}
                  defaultCountry="NZ"
                  onChange={onChange}
                  error={fieldError?.message || error}
                  noErrorMessage={noErrorMessage}
                />
              )}
            />
          </InputGroup>
        );
      case 'date':
        const calculateDateConstraint = value => {
          if (value === null || value === undefined) return undefined;
          if (value === 0) return moment().toDate();
          return moment().add(value, 'months').toDate();
        };

        return (
          <InputGroup title={title} description={description} tooltip={tooltip} error={error}>
            <Controller
              name={fieldName}
              defaultValue={
                defaultValue === 'NOW' ? moment().format('DD/MM/YYYY') : defaultValue || ''
              }
              control={control}
              rules={validators}
              render={({ field: { onChange, value }, fieldState: { error: fieldError } }) => (
                <DatePicker
                  value={value}
                  useDefaultToday={false}
                  minDate={calculateDateConstraint(min)}
                  maxDate={calculateDateConstraint(max)}
                  format={dateHyphenFormat}
                  outputFormat="DD/MM/YYYY"
                  onChange={onChange}
                  error={fieldError?.message || error}
                  noErrorMessage={noErrorMessage}
                />
              )}
            />
          </InputGroup>
        );
      case 'date_selector':
      case 'date_selector_no_day':
        return (
          <InputGroup title={title} description={description} tooltip={tooltip} error={error}>
            <Controller
              name={fieldName}
              defaultValue={
                defaultValue === 'NOW' ? moment().format('DD/MM/YYYY') : defaultValue || ''
              }
              control={control}
              rules={validators}
              render={({ field: { onChange, value }, fieldState: { error: fieldError } }) => {
                const today = moment();

                // Calculate min/max years by adding months to today's date
                // Floor min year and ceil max year to get inclusive year boundaries
                const minYear =
                  min !== null ? Math.floor(today.clone().add(min, 'months').year()) : undefined;
                const maxYear =
                  max !== null ? Math.ceil(today.clone().add(max, 'months').year()) : undefined;

                return (
                  <DateSelector
                    name={fieldName}
                    value={value}
                    no_day={type === 'date_selector_no_day'}
                    firstYear={minYear}
                    lastYear={maxYear}
                    outputFormat="DD/MM/YYYY"
                    onChange={onChange}
                    error={fieldError?.message || error}
                  />
                );
              }}
            />
          </InputGroup>
        );
      case 'checkbox':
        return (
          <Controller
            name={fieldName}
            control={control}
            defaultValue={
              defaultValue === true ||
              defaultValue === 'true' ||
              defaultValue === '1' ||
              defaultValue === 1
            }
            rules={validators}
            render={({ field: { onChange, value } }) => (
              <Checkbox
                name={fieldName}
                label={title}
                tooltip={tooltip}
                checked={value}
                onClick={onChange}
              />
            )}
          />
        );
      case 'radio_button':
        return (
          <InputGroup title={title} description={description} tooltip={tooltip} error={error}>
            <Controller
              name={fieldName}
              defaultValue={defaultValue}
              control={control}
              rules={validators}
              render={({ field: { onChange, value } }) => (
                <MultipleChoice
                  value={value}
                  options={{ options: fieldOptions }}
                  onChange={onChange}
                  radioButtonStyle
                  error={error}
                  noErrorMessage={noErrorMessage}
                />
              )}
            />
          </InputGroup>
        );
      case 'select':
        return (
          <InputGroup title={title} description={description} tooltip={tooltip} error={error}>
            <Select
              name={fieldName}
              value={watch(fieldName)}
              placeholder={placeholder || 'Please Select...'}
              defaultValue={defaultValue}
              useDefault={useDefault}
              useSearch={useSearch}
              validators={validators}
              register={register}
              error={error}
              noErrorMessage={noErrorMessage}>
              {(fieldOptions || []).map(option => (
                <Select.Item key={option.value} value={option.value}>
                  {option.name}
                </Select.Item>
              ))}
            </Select>
          </InputGroup>
        );
      case 'multi_select':
        return (
          <InputGroup title={title} description={description} tooltip={tooltip} error={error}>
            <Controller
              name={fieldName}
              control={control}
              rules={validators}
              render={({ field: { onChange, value }, fieldState: { error: fieldError } }) => (
                <MultipleSelect
                  value={value}
                  placeholder={placeholder || 'Please Select...'}
                  minSelect={min !== null ? Number(min) : undefined}
                  maxSelect={max !== null ? Number(max) : undefined}
                  useSearch={useSearch}
                  onChange={onChange}
                  error={fieldError?.message || error}
                  noErrorMessage={noErrorMessage}>
                  {fieldOptions.map(option => (
                    <MultipleSelect.Item
                      key={option.value}
                      value={option.value}
                      active={watch(fieldName)?.includes(option.value)}>
                      {option.name}
                    </MultipleSelect.Item>
                  ))}
                </MultipleSelect>
              )}
            />
          </InputGroup>
        );
      case 'country':
        return (
          <InputGroup title={title} description={description} tooltip={tooltip} error={error}>
            <Select
              name={fieldName}
              value={watch(fieldName)}
              placeholder={placeholder || 'Select Country...'}
              defaultValue={defaultValue}
              useDefault={useDefault}
              useSearch
              validators={validators}
              register={register}
              error={error}
              noErrorMessage={noErrorMessage}>
              {countries.map(country => (
                <Select.Item key={country.value} value={country.value}>
                  {country.name}
                </Select.Item>
              ))}
            </Select>
          </InputGroup>
        );
      case 'alpha3-country':
        return (
          <InputGroup title={title} description={description} tooltip={tooltip} error={error}>
            <Select
              name={fieldName}
              value={watch(fieldName)}
              placeholder={placeholder || 'Select Country...'}
              defaultValue={defaultValue}
              useDefault={useDefault}
              useSearch
              validators={validators}
              register={register}
              error={error}
              noErrorMessage={noErrorMessage}>
              {Alpha3Countries.map(country => (
                <Select.Item key={country.code} value={country.code}>
                  {country.name}
                </Select.Item>
              ))}
            </Select>
          </InputGroup>
        );
      case 'multi_country':
        return (
          <InputGroup title={title} description={description} tooltip={tooltip} error={error}>
            <Controller
              name={fieldName}
              control={control}
              rules={validators}
              render={({ field: { onChange, value }, fieldState: { error: fieldError } }) => (
                <MultipleSelect
                  value={value}
                  placeholder={placeholder || 'Select Countries...'}
                  minSelect={min !== null ? Number(min) : undefined}
                  maxSelect={max !== null ? Number(max) : undefined}
                  useSearch
                  onChange={onChange}
                  error={fieldError?.message || error}
                  noErrorMessage={noErrorMessage}>
                  {countries.map((country, i) => (
                    <MultipleSelect.Item
                      key={i}
                      value={country.value}
                      active={watch(fieldName)?.includes(country.value)}>
                      {country.name}
                    </MultipleSelect.Item>
                  ))}
                </MultipleSelect>
              )}
            />
          </InputGroup>
        );
      default:
        return null;
    }
  };

  return render();
};

DynamicTypeInput.propTypes = {
  fieldName: PropTypes.string,
  type: PropTypes.oneOf([
    'text',
    'textarea',
    'number',
    'address',
    'phone_number',
    'date',
    'date_selector',
    'date_selector_no_day',
    'checkbox',
    'radio_button',
    'select',
    'multi_select',
    'country',
    'alpha3-country',
    'multi_country',
  ]),
  title: PropTypes.string,
  description: PropTypes.string,
  tooltip: PropTypes.string,
  autoComplete: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  min: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string, // Allow string for date inputs
  ]),
  max: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string, // Allow string for date inputs
  ]),
  required: PropTypes.bool,
  validatorName: PropTypes.string,
  validationParams: PropTypes.object,
  fieldOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    }),
  ),
  useDefault: PropTypes.bool,
  useSearch: PropTypes.bool,
  locale: PropTypes.oneOf(['en_GB', 'en_US', 'en-GB', 'en-US']),
  register: PropTypes.func,
  watch: PropTypes.func,
  control: PropTypes.object,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  error: PropTypes.string,
  errorObject: PropTypes.object,
  noErrorMessage: PropTypes.bool,
};
