import { useEffect, useState } from 'react';

import cn from 'classnames';
import { CandidateCheckScreens } from 'constants/candidate_screens';
import { useQueryParams } from 'hooks/query-params';
import CandidatePage from 'layouts/CandidatePage/CandidatePage';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { CustomButton } from '../components/CustomButton/CustomButton';
import { CustomLink } from '../components/CustomLink/CustomLink';
import { AnimatedModal } from 'components/AnimatedModal/AnimatedModal';

import { selectCandidateApplication } from 'api/candidate-application-slice';
import { useSetHistoryLinkCandidateByTokenMutation } from 'api/candidates-api';

import styles from './CandidateIntroduction.module.scss';

const CandidateIntroduction = () => {
  const navigate = useNavigate();

  const { token } = useParams();

  const {
    application: { candidate, brand, candidate_checks, forms, check_types },
    idVerification,
  } = useSelector(selectCandidateApplication);

  const [setHistoryLinkCandidate] = useSetHistoryLinkCandidateByTokenMutation();

  const [utm_source] = useQueryParams(['utm_source']);
  const [showIntroduction, setShowIntroduction] = useState(true);

  // Save utm_source
  useEffect(() => {
    if (!utm_source) return;

    setHistoryLinkCandidate(token);
  }, [utm_source]);

  const getQuestionnaireFormTitle = () => {
    const candidate_check = candidate_checks.find(
      check => check.type === 'candidate_questionnaire',
    );
    const form_id = candidate_check.details.form_id;
    const form = forms.find(form => form.id === form_id);
    return form.name;
  };

  const onRedirect = () => {
    const screen = idVerification.required
      ? `${CandidateCheckScreens.ID_VERIFICATION}/introduction`
      : CandidateCheckScreens.MENU;
    navigate(`/form_submission/candidate/${screen}/${token}`);
  };

  return (
    <CandidatePage token={token}>
      <AnimatedModal visible={showIntroduction} small noClose title="Introduction to Checkmate">
        <p className="u-margin-top--large">
          Checkmate is a secure verification platform designed to simplify the process for employers
          and employees. The privacy and security of your data is our highest priority, and we have
          several systems and processes to keep your information safe and secure.
          <br />
          <br />
          Checkmate is ISO27001 Certified and uses encrypted connections to ensure your privacy and
          confidentiality is maintained throughout this application. We are externally audited
          annually and commission regular independent tests to ensure the security of our system.
          <br />
          <br />
          Checkmate performs several employment checks and verification on behalf of employers; we
          will list the checks we are processing with you on the next page and request your signed
          consent upon completion.
          <br />
          <br />
          All information you supply is kept strictly confidential and is only accessible by
          authorised staff.
          <br />
          <br />
          Before proceeding, please read our{' '}
          <CustomLink
            brand={brand}
            href="https://www.checkmate.tech/utility/candidate-consent-statement">
            Candidate Consent Statement
          </CustomLink>
          ,{' '}
          <CustomLink brand={brand} href="https://www.checkmate.tech/utility/privacy-policy">
            Privacy Policy
          </CustomLink>{' '}
          and{' '}
          <CustomLink brand={brand} href="https://www.checkmate.tech/utility/terms-and-conditions">
            Terms and Conditions
          </CustomLink>{' '}
          here. By closing this window, you confirm that you have read, understood and agree to
          proceed.
          <br />
          <br />
          If you have any questions or concerns please contact our support team via email at{' '}
          <CustomLink brand={brand} href="mailto:team@checkmate.tech">
            team@checkmate.tech
          </CustomLink>
          .
        </p>
        <CustomButton
          brand={brand}
          small
          onClick={() => setShowIntroduction(false)}
          className="u-margin-top--large">
          Close Window
        </CustomButton>
      </AnimatedModal>

      <CandidatePage.View>
        <CandidatePage.Card>
          <CandidatePage.Header
            brand={brand}
            candidateName={candidate?.first_name}
            subtitle={
              <p>
                Welcome to Checkmate! This system is a secure verification platform designed to
                simplify the process for employers and employees.
                <br />
                <br />
                In this application, we will be performing the following checks:
              </p>
            }
          />
          <div
            className={cn(
              'u-width-100',
              'card',
              'card-with-border',
              'background-secondary',
              styles.checkTypesWrapper,
            )}>
            {(check_types || []).map(check_type => (
              <div
                key={check_type?.slug}
                className={cn('d-flex', 'align-items-start', styles.checkType)}>
                <img className={cn(styles.logo, 'u-margin-right--small')} src={check_type?.logo} />
                <div>
                  <h4 className={cn('title-4', 'u-margin-top')}>
                    {check_type?.slug === 'candidate_questionnaire'
                      ? getQuestionnaireFormTitle()
                      : check_type?.title}
                  </h4>
                </div>
              </div>
            ))}
          </div>

          <div className="d-flex">
            <CustomButton
              type="secondary"
              small
              className="u-margin-right--small"
              brand={brand}
              onClick={() => window.location.replace('https://www.checkmate.tech')}>
              Decline & Exit
            </CustomButton>
            <CustomButton brand={brand} small className="u-margin-left--small" onClick={onRedirect}>
              Get Started
            </CustomButton>
          </div>
        </CandidatePage.Card>
      </CandidatePage.View>
    </CandidatePage>
  );
};

export default CandidateIntroduction;
